import React from "react";
import ReactDOM from "react-dom/client";
import { Store } from "@ludens-reklame/rubics-v3-sdk/store/types";
import { Section } from "@ludens-reklame/rubics-v3-sdk/section/types";
import { App } from "./app";

const Header = React.lazy(() => import("./components/header/header"));
const Footer = React.lazy(() => import("./components/footer/footer"));
const Banner = React.lazy(() => import("./components/banner/banner"));
const TopBanner = React.lazy(() => import("./components/topBanner/topBanner"));
const CTABanner = React.lazy(() => import("./components/ctaBanner/ctaBanner"));
const PluggGrid = React.lazy(() => import("./components/pluggGrid/pluggGrid"));
const CardGrid = React.lazy(() => import("./components/cardGrid/cardGrid"));
const Article = React.lazy(() => import("./components/article/article"));
const ImageGrid = React.lazy(() => import("./components/imageGrid/imageGrid"));
const Login = React.lazy(() => import("./components/login/login"));
const MyPage = React.lazy(() => import("./components/myPage/myPage"));
const Checkout = React.lazy(() => import("./components/checkout/checkout"));
const Cart = React.lazy(() => import("./components/cart/cart"));
const Questionnaire = React.lazy(
  () => import("./components/questionnaire/questionnaire")
);
const RohsCalculator = React.lazy(
  () => import("./components/rohsCalculator/rohsCalculator")
);
const SolarPanelCalculator = React.lazy(
  () => import("./components/solarPanelCalculator/solarPanelCalculator")
);
const ProductPage = React.lazy(
  () => import("./components/productPage/productPage")
);
const SearchResults = React.lazy(
  () => import("./components/searchResults/searchResults")
);
const InteractiveMap = React.lazy(
  () => import("./components/interactiveMap/interactiveMap")
);
const ProductPlugg = React.lazy(
  () => import("./components/productPlugg/productPlugg")
);
const ContactForm = React.lazy(
  () => import("./components/contactForm/contactForm")
);
const RelatedProducts = React.lazy(
  () => import("./components/relatedProducts/relatedProducts")
);
const ImageCarousel = React.lazy(
  () => import("./components/imageCarousel/imageCarousel")
);
const ImageVideo = React.lazy(
  () => import("./components/imageVideo/imageVideo")
);
const LogoCarousel = React.lazy(
  () => import("./components/logoCarousel/logoCarousel")
);
const SelectedPages = React.lazy(
  () => import("./components/selectedPages/selectedPages")
);
const ServiceGrid = React.lazy(
  () => import("./components/serviceGrid/serviceGrid")
);
const Infographic = React.lazy(
  () => import("./components/infographic/infographic")
);
const ImageRichtext = React.lazy(
  () => import("./components/imageRichtext/imageRichtext")
);
const ProductSearch = React.lazy(
  () => import("./components/productSearch/productSearch")
);
const MyMinel = React.lazy(() => import("./components/myMinel/myMinel"));
const FindElectrician = React.lazy(
  () => import("./components/findElectrician/findElectrician")
);

const FindElectricianBanner = React.lazy(
  () => import("./components/findElectricianBanner/findElectricianBanner")
);

declare var _STORE: Store;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App store={_STORE} renderer={renderSection} />
  </React.StrictMode>
);

function renderSection(
  section: Section,
  children?: React.ReactNode
): React.ReactNode {
  let suspend: boolean = true;
  let Component: React.ComponentType<any>;
  const domElem = document.getElementById(section.name);

  switch (section.component) {
    case "Questionnaire":
      Component = Questionnaire;
      break;
    case "RohsCalculator":
      Component = RohsCalculator;
      break;
    case "SolarPanelCalculator":
      Component = SolarPanelCalculator;
      break;
    case "ProductPage":
      Component = ProductPage;
      break;
    case "Checkout":
      Component = Checkout;
      break;
    case "Cart":
      Component = Cart;
      break;
    case "SearchResults":
      Component = SearchResults;
      break;
    case "InteractiveMap":
      Component = InteractiveMap;
      break;
    case "ProductPlugg":
      Component = ProductPlugg;
      break;
    case "ContactForm":
      Component = ContactForm;
      break;
    case "MyPage":
      Component = MyPage;
      break;
    case "Login":
      Component = Login;
      break;
    case "RelatedProducts":
      Component = RelatedProducts;
      break;
    case "ImageCarousel":
      Component = ImageCarousel;
      break;
    case "ImageRichtext":
      Component = ImageRichtext;
      break;
    case "ImageGrid":
      Component = ImageGrid;
      break;
    case "ImageVideo":
      Component = ImageVideo;
      break;
    case "LogoCarousel":
      Component = LogoCarousel;
      break;
    case "Article":
      Component = Article;
      break;
    case "Infographic":
      Component = Infographic;
      break;
    case "ServiceGrid":
      Component = ServiceGrid;
      break;
    case "CardGrid":
      Component = CardGrid;
      break;
    case "PluggGrid":
      Component = PluggGrid;
      break;
    case "SelectedPages":
      Component = SelectedPages;
      break;
    case "CTABanner":
      Component = CTABanner;
      break;
    case "TopBanner":
      Component = TopBanner;
      break;
    case "Banner":
      Component = Banner;
      break;
    case "Footer":
      Component = Footer;
      break;
    case "Header":
      Component = Header;
      break;
    case "ProductSearch":
      Component = ProductSearch;
      break;
    case "MyMinel":
      Component = MyMinel;
      break;
    case "FindElectrician":
      Component = FindElectrician;
      break;
    case "FindElectricianBanner":
      Component = FindElectricianBanner;
      break;
    default:
      return null;
  }

  if (!suspend) {
    return <Component {...section.props}>{children}</Component>;
  }

  return (
    <React.Suspense
      fallback={
        domElem ? (
          <div dangerouslySetInnerHTML={{ __html: domElem.innerHTML }} />
        ) : null
      }
    >
      {/* @ts-ignore */}
      <Component {...section.props}>{children}</Component>
    </React.Suspense>
  );
}
